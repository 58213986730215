<template>
  <div class="raffles-page">
    <a-row type="flex" justify="space-between">
      <a-col :lg="10" :md="10" :sm="24" :xs="24">
        <h2 class="list-title">
          <a-icon type="folder" class="list-icon" />
          <span>{{ labels.title }}</span>
        </h2>
        <p class="list-subtitle">{{ labels.subtitle }}</p>
      </a-col>
      <a-col :lg="14" :md="14" :sm="24" :xs="24">
        <a-row
          type="flex"
          justify="end"
          :gutter="16"
          :style="{
            alignItems: 'flex-end',
            marginTop: '25px',
            marginBottom: '10px',
          }"
        >
          <a-col :lg="18" :md="18" :sm="20" :xs="16">
            <a-input-search
              :placeholder="labels.placeholderSearch"
              style="width: 100%"
              @search="handleSearch"
              allowClear
            />
          </a-col>
          <a-col
            v-if="canSave"
            :lg="6"
            :md="6"
            :sm="4"
            :xs="8"
            :style="{ textAlign: 'right' }"
          >
            <router-link :to="{ name: 'admin.raffles.create' }">
              <a-button type="default" ghost icon="plus" class="add-button">
                <span>{{ labels.add }}</span>
              </a-button>
            </router-link>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24">
        <StandardTable
          ref="standardTable"
          :fetchService="fetchRaffles"
          :columns="columns"
          :locale="locale"
          :handleShowTotal="handleShowTotal"
          :scroll="{ x: true }"
          :paginationType="isMobile ? 'arrows' : 'numbers'"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import labels from "@/utils/labels";
import StandardTable from "../../../components/core/table/StandardTable.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { serverDateFormat } from "@/utils/utils";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";
import _ from "lodash";
export default {
  components: { StandardTable },
  name: "RaffleList",
  metaInfo: {
    title: adminPageTitle("Sorteos"),
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  data() {
    return {
      serverDateFormat,
      labels: labels.raffles,
      search: "",
      activeRow: null,
    };
  },
  methods: {
    ...mapActions("raffles", ["fetchRaffles", "deleteRaffle"]),
    handleReloadTable() {
      let filtersTable = this.$refs.standardTable.filters;
      let result = { ...filtersTable };
      this.$refs.standardTable.handleFilters(result);
    },
    handleSearch(search) {
      this.$refs.standardTable.handleSearch(search);
    },
    handleShowTotal(total, range) {
      return `${range[0]}-${range[1]} de ${total}`;
    },
    confirmDelete(id) {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "delete",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteRaffle(id);
            this.$message.success(labels.deletedSuccess);
            this.handleReloadTable();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                labels.deleteError
            );
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 600;
    },
    canSave() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_RAFFLES_SAVE]);
    },
    locale() {
      return {
        emptyText: this.labels.table.emptyText,
      };
    },
    columns() {
      return [
        {
          title: this.labels.table.columns.date,
          dataIndex: "date",
          key: "date",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return text
              ? this.$moment(text, this.serverDateFormat).format(
                  DATE_FORMAT.MOMENT_DATE
                )
              : "-";
          },
        },
        {
          title: this.labels.table.columns.createdBy,
          dataIndex: "created_by",
          key: "created_by",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return text?.name || "-";
          },
        },
        {
          title: this.labels.table.columns.createdAt,
          dataIndex: "created_at",
          key: "created_at",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text, this.serverDateFormat).format(
              "DD/MM/YYYY H:mm:ss A"
            );
          },
        },
        {
          title: this.labels.table.columns.updatedBy,
          dataIndex: "updated_by",
          key: "updated_by",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return text?.name || "-";
          },
        },
        {
          title: this.labels.table.columns.updatedAt,
          dataIndex: "updated_at",
          key: "updated_at",
          sorter: true,
          hidden: this.isMobile,
          customRender: (text) => {
            return this.$moment(text, this.serverDateFormat).format(
              "DD/MM/YYYY H:mm:ss A"
            );
          },
        },
        {
          title: this.labels.table.columns.action.title,
          dataIndex: "action",
          key: "action",
          width: "120px",
          hidden: this.isMobile,
          customRender: (text, record) => {
            return (
              <div class="action-buttons">
                <router-link
                  to={{
                    name: "admin.raffles.edit",
                    params: {
                      date: this.$moment(
                        record.date,
                        this.serverDateFormat
                      ).format("YYYYMMDD"),
                    },
                  }}
                  title={this.labels.table.columns.action.edit}
                >
                  <a-button
                    type="link"
                    icon="edit"
                    class="action-button-edit"
                  />
                </router-link>
                {false && (
                  <a-button
                    type="link"
                    icon="delete"
                    class="action-button-delete"
                    onClick={() => this.confirmDelete(record.date)}
                    title={this.labels.table.columns.action.delete}
                  />
                )}
              </div>
            );
          },
        },
        {
          title: this.labels.table.columns.raffles,
          dataIndex: "raffles",
          key: "raffles",
          sorter: false,
          hidden: !this.isMobile,
          customRender: (_, record) => {
            // Card item design
            return (
              <div class="list-item">
                <div class="list-item-head">
                  <div class="list-item-head-content">
                    <div class="list-item-head-content-date">
                      <span>
                        {this.$options.filters.ucwords(
                          this.$moment(record.date).format(
                            DATE_FORMAT.MOMENT_DAY_MONTH_YEAR
                          )
                        )}
                      </span>
                    </div>
                    <div class="list-item-head-content-created-by">
                      <span>{record.created_by?.name || "TuJugada"}</span>
                    </div>
                  </div>
                  <div class="list-item-head-footer">
                    <a-button-group>
                      <router-link
                        class="edit-button ant-btn ant-btn-primary ant-btn-block"
                        to={{
                          name: "admin.raffles.edit",
                          params: {
                            date: this.$moment(
                              record.date,
                              this.serverDateFormat
                            ).format("YYYYMMDD"),
                          },
                        }}
                        title={this.labels.table.columns.action.edit}
                      >
                        {this.labels.table.columns.action.edit}
                      </router-link>
                    </a-button-group>
                  </div>
                </div>
              </div>
            );
          },
        },
      ].filter((column) => !column.hidden);
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.raffles-page {
  .list {
    &-title {
      margin-bottom: 10px;
      .list-icon {
        margin-right: 10px;
      }
    }
    &-subtitle {
      margin-bottom: 10px;
    }
  }
  .add-button {
    color: color(primary);
    border-color: color(primary);
  }
  .action-buttons {
    display: flex;
    justify-content: space-between;
    .action-button-edit {
      color: color(primary);
    }
    .action-button-delete {
      color: color(danger);
    }
  }
  .list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 6px;
      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 2px;
        max-width: 70%;
        width: 70%;
        overflow: hidden;
        &-date {
          span {
            font-size: 16px;
            font-weight: 600;
            text-overflow: ellipsis;
          }
        }
        &-created-by {
          span {
            font-size: 14px;
            font-weight: 500;
            text-overflow: ellipsis;
          }
        }
      }
      &-footer {
        max-width: 30%;
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: right;
        gap: 2px;
      }
    }
  }
}
</style>
